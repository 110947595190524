import coloredLogo from "../assets/logo1.png"
import whiteLogo from "../assets/logo2.png"
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import facebook from "../assets/Facebook-01.png"
import instagram from "../assets/Instra-01.png"
import linkedin from "../assets/Linkedin-01.png"
import { useMediaQuery } from 'react-responsive'
import "../../components/Contact/Contactus"
import "../Header/styles.css"
import { Link } from "react-router-dom";


const PortHeader = () => {
    const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 767 })
        return isMobile ? children : null
    }
    const [isScrolled, setIsScrolled] = useState(false);
    const [open, setOpen] = useState(false);
   
    const listenScrollEvent = () => setIsScrolled(window.scrollY > 120);
    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);
    }, []);
  return (
    <section className="page-title parallax-section about"  >

    <nav className="navbar navbar-expand-lg fixed-top stroke bg-dark"  >
        <NavLink to="/"><img src={isScrolled ? whiteLogo : coloredLogo}
            className="logo"
            alt={isScrolled ? "SmartLogger white logo" : "SmartLogger colored logo"}
        /></NavLink>
        <input type="checkbox" id="click" />
        <label for="click" onClick={() => setOpen(!open)} class="menu-btn navbar-toggler bg-warning menu-btn">
            <ion-icon name={open ? 'close' : 'menu'}></ion-icon>
        </label>

        <ul className="navbar-nav ms-auto  py-5 mb-lg-0 fs-5 my-2 mx-5" style={{ cursor: "pointer" }}>
            <li className="nav-item ">
                <a className="nav-link " aria-current="page"  href="/">Home </a>
            </li>
            <li className="nav-item">
                <Link smooth className="nav-link  "  to="/about">About </Link>
            </li>
            <li className="nav-item">
                <Link smooth className="nav-link  "  to="/services">Services </Link>
            </li>
            <li className="nav-item">
                <Link smooth className="nav-link  "  to="/client">Clients</Link>
            </li>
            <li className="nav-item">
                <Link smooth className="nav-link " to="/portfolio">Portfolio</Link>
            </li>
            <li className="nav-items">
                <Link smooth className="nav-link nav-button  btn btn-outline-warning  d-inline-block"  to="/contact">Contact</Link>
            </li>

            <Mobile>
                <div className="socials">
                    <div className="row">
                        <div className="col-lg-12 d-flex justify-content-center my-3 ">
                            <li className="social-item-facebook  "><a target="_blank" href="https://www.facebook.com/azcreativesol"  ><img src={facebook} className="socialimages" /></a> </li>
                            <li className="social-item-linkedin "><a target="_blank" href="https://www.linkedin.com/company/azcreativesol"  ><img src={linkedin} className="socialimages" /></a></li>
                            <li className="social-item-instagram  "><a target="_blank" href="https://www.instagram.com/azcreativesol"  ><img src={instagram} className="socialimages" /></a></li>
                        </div>
                    </div>
                </div>
            </Mobile>
        </ul>

    </nav>
</section>
  )
}

export default PortHeader