
import Header from '../Header/Header'
import AboutImg from "../../Images/AboutDesktop.jpg"
import AboutImg1 from "../../Images/AboutMobile.jpg"
import Footer from '../Footer/Footer'
import "../../App.css"
import "../Services/service.css"
import aboutimage from "../../Images/1.png"
// import { useMediaQuery } from 'react-responsive'


const About = () => {
    // const Desktop = ({ children }) => {
    //     const isDesktop = useMediaQuery({ minWidth: 801 })
    //     return isDesktop ? children : null
    // }

    // const Mobile = ({ children }) => {
    //     const isMobile = useMediaQuery({ maxWidth:800 })
    //     return isMobile ? children : null
    // }
    return (
        <>
            <div id='wrapper'  >
                <Header />
                <section>
                    <div className="slide-wrap dark-overlay">
                        <div className="slidecontent">
                            <div className="container-fluid" >
                                <div>
                                    {/* <Desktop> <img src={AboutImg} className="aboutimage " alt="backround" /></Desktop>
                                   <Mobile> <img src={AboutImg1} className="aboutimage " alt="backround" /></Mobile> */}
                                    <img src={AboutImg} className="aboutimage " alt="backround" style={{ maxWidth: "100%", maxheight: "100%" }} />
                                </div>
                                <h1 className='align-middle abouth1 '>About</h1>
                            </div>
                        </div>
                    </div>
                    <div className="skewed-masks">
                        <div className="mask-blocks" >
                        </div>
                    </div>
                </section>
            </div>

            <div className='aboutsec1 ' >
                <section className="about text-dark " id="about" >
                    <div className="container " >
                        <div className="section1 "  >
                            <p className='span4  '>We sow and reap creative ideas</p>
                            <p className='para1 text-center '>AZ Creative is a leading SEO company and full-service digital agency that focuses on providing holistic creative, IT and digital solutions since 2021. We build your business from scratch, and turn it into an ideal brand for your target customers. We have first-hand subject matter expertise on the dynamic nature of IT services and products around the world, and we rigorously work to stay ahead of the knowledge to provide the best service to our clients. We want to be the first place you go for all your website design, graphic design, development, search engine optimization (SEO), social media, app development, digital marketing, logo, branding and all other digital needs. We promise to provide the best and most efficient service today for the future of your business tomorrow!
                            </p>
                        </div>
                        <div className=''>
                            <p className='aboutpa2 text-center'><span className='span1 text-warning text-center d-block mt-5'>How we work <br /></span> <span className='span2'> We are experts in bringing</span> <br /> <span className='span3'> Brands to life digitally </span></p>
                            <img className='abtimage ' style={{ maxWidth: "100%", maxheight: "100%" }} src={aboutimage} data-aos="fade-right" data-aos-duration="2000" />

                        </div>

                    </div>
                </section>
            </div>

            <section className='aboutend'>
                <Footer />
            </section>

        </>
    )
}

export default About