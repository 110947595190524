import React from 'react'
import "./footer.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
import FooterImg from "../../components/assets/logo1.png"
import facebook from "../../components/assets/Facebook-01.png"
import instagram from "../../components/assets/Instra-01.png"
import linkedin from "../../components/assets/Linkedin-01.png"
AOS.init();
const Footer = () => {
    return (
        <div>
            <footer id="footer">
                <div className="footer-copy">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <img src={FooterImg} alt="footer-img" className='footerImg' />
                                <p className='paragraph text-center'>AZ Creative Solutions llc is a full-service digital company  where  we  believe
                                    in creating  big ideas into a reality with our hard work.</p>

                                <h3 className='footerh3 text-center text-light '>Lets Discuss </h3>
                                <span className='footerPhoneImg  text-light '> <i class="fas fa-phone-alt"></i>  +1 (469) 288-9263</span> <br />
                                <span className='footerPhoneImg  text-light '> <i class="fas fa-envelope"></i> contact@azcreativesol.com</span>
                                {/* <ul className="social-list"> */}
                                </div>
                                <div class="social-icons">
                                        <div className="col-lg-12 d-flex justify-content-center my-2 ">
                                            <a href="https://www.facebook.com/azcreativesol" target="_blank"><img src={facebook} className="socialimages" /></a>
                                            <a href="https://www.linkedin.com/company/azcreativesol" target="_blank"><img src={linkedin} className="socialimages" /></a>
                                            <a href="https://www.instagram.com/azcreativesol" target="_blank"><img src={instagram} className="socialimages" /></a>
                                           
                                        </div>
                                    </div>
                                {/* </ul></div> */}
                            <div className="col-sm-12 text-center">
                                <div className="copy-text" >
                                    <p className='footerend text-light'>© 2021-2022 AZ CREATIVE SOLUTIONS LLC</p>

                                    <p className='footerend text-light'>All Rights Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer