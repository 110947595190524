import React, { useEffect } from 'react';
import {
    Routes,
    Route,
} from "react-router-dom";
import About from '../components/About/About';
import Client from '../components/Client/Client';
import Contact from '../components/Contact/Contactus';
import Navbar from '../components/Header/Navbar';
import Portfolio from '../components/Portfolio/Portfolio';
import Seriveces from '../components/Services/Seriveces';
import Logo from '../components/Portfolio/Logo';
import 'react-spinner-animated/dist/index.css'
import Socialmedia from '../components/Portfolio/Socialmedia';
import Web from '../components/Portfolio/Web';
import Flyer from '../components/Portfolio/Flyer';
import { useMediaQuery } from 'react-responsive'

const Router = () => {
    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 820 })
        return isDesktop ? children : null
    }

    const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 800 })
        return isMobile ? children : null
    }
    // const [loading, setLoading] = useState(false);
    useEffect(() => {
        // setLoading(true);
        // setTimeout(() => {
        //     setLoading(false);
        // }, 2000);
    }, []);
    return (
        <div>
            {/* {loading ? (
                <DoubleOrbit text={"Loading..."} bgColor={"#fcbe24"}
                    width={"100%"} height={"100vh"} />
            ) : ( */}
            <Routes>
                <Route index element={<Navbar  />} />
                <Route path='/services' element={<Seriveces />} />
                <Route path='/about' element={<About />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/client' element={<Client />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/logo" element={<Logo />} />
                <Route path="/socialmedia" element={<Socialmedia />}> </Route>
                <Route path="/web" element={<Web />} />
                <Route path="/flyer" element={<Flyer />} />
            </Routes>
            {/* )} */}
        </div>
    )
}

export default Router