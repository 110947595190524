import React, { useRef } from 'react'
import "../Contact/contact.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import { useState, useEffect } from "react";
import AOS from 'aos';
import axios from 'axios';

AOS.init();
const Form = () => {
    const form = useRef();
    // const [userapi2, setUserApi2] = useState([]);
    // const sendEmail = async () => {
    //     const res = await axios.post("/applyjob");
    //     setUserApi2(res.data);
    //     console.log(res.data);
    // }
    // useEffect(() => {
    //     sendEmail();
    // }, [])
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        phone: '',
        city: '',
        state: '',
    });
    console.log(formData)
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send form data to backend server
            const response = await axios.post('http://localhost:5000/api/sendEmail', formData);
            // setSuccessMessage('Your message has been successfully sent!');
            setErrorMessage('');
            toast('Thanks For contact', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark"
            });

        } catch (error) {
            console.error(error);
            setSuccessMessage('');
            setErrorMessage('Failed to send your message. Please try again later.');
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }
    // const sendEmail = (e) => {
    //     e.preventDefault();

    //     emailjs.sendForm('service_67pfxqt', 'template_cybspb7', form.current, 'XpO4LAg4H6M2T3jQV')
    //         .then((result) => {
    //             console.log(result.text);
    //         }, (error) => {
    //             console.log(error.text);
    //         });
    //     form.current.reset()


    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
            />

            <div className="container " data-aos="fade-right"
                data-aos-duration="3000">

                <div className="row py-5 ">
                    <div class="col-lg-12  mt-lg-0">
                        <form onSubmit={handleSubmit} ref={form}  >
                            <div className="row py-3">
                                <h1 className='text-center mx-4 input-field'>Get In Touch
                                    <p className='text-center  form-field  mx-4 my-2'>Let's work together </p>
                                    <p className='text-center  form-field mx-4'> AZ Creative & You!</p>
                                </h1>
                                <div className="form col-md-6 form-group " >
                                    <input type="text" className="form-control "
                                        name="name"
                                        placeholder="Full Name"
                                        value={formData.name} onChange={handleChange}
                                        required />
                                </div>
                                <div className="form col-md-6 form-group " >
                                    <input type="email" className="form-control" name="email" id="email"
                                        value={formData.email} onChange={handleChange}
                                        placeholder="Work Email"
                                        required />
                                </div>
                                <div className="form col-md-6 form-group " >
                                    <input type="Number" className="form-control" name="phone"
                                        value={formData.phone} onChange={handleChange}
                                        placeholder="Phone Number"
                                        required />
                                </div>
                                <div className="form col-md-6 form-group " >
                                    <input type="text" className="form-control" name="CompanyName"
                                        value={formData.companyname} onChange={handleChange}
                                        placeholder="Company Name * "
                                    />
                                </div>
                                <div className="form col-md-6 form-group " >
                                    <input type="text" className="form-control"
                                        value={formData.city} onChange={handleChange}
                                        placeholder="City" name='city'
                                        required />
                                </div>
                                <div class="form col-md-6 form-group col-md-6">
                                    <select id="inputState" class="form-select" name='state' value={formData.state} onChange={handleChange}>
                                        <option selected>Choose Your State </option>
                                        <option>Alabama (AL)</option>
                                        <option>Alaska (AK)</option>
                                        <option>Arizona (AZ)</option>
                                        <option>Arkansas (AR)</option>
                                        <option>California (CA)</option>
                                        <option>Colorado (CO)</option>
                                        <option>Connecticut (CT)</option>
                                        <option>Florida (FL)</option>
                                        <option>Georgia (GA)</option>
                                        <option>Hawaii (HI)</option>
                                        <option>Illinois (IL)</option>
                                        <option>New York (NY)</option>
                                        <option>Texas (TX)</option>
                                        <option>Washington (WA)</option>
                                        <option>Indiana (IN)</option>
                                        <option>Illinois (IL)</option>
                                        <option>New Jersey (NJ)</option>
                                        <option>North Carolina (NC)</option>
                                    </select>
                                </div>
                                <div className="form col-md-12 form-group ">
                                    <textarea className="form-control" value={formData.message} onChange={handleChange} name="message" rows="5" placeholder="How Can We Help?"
                                        required>
                                    </textarea>
                                </div>
                                <div className="form text-center py-3 my-3 ">
                                    <button className='sub' type="submit"> Send Message <i class="fas fa-paper-plane mx-2"></i></button>
                                    {successMessage && <p>{successMessage}</p>}
                                    {errorMessage && <p>{errorMessage}</p>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Form