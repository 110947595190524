import coloredLogo from "../../components/assets/logo1.png"
import whiteLogo from "../../components/assets/logo2.png"
import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import facebook from "../../components/assets/Facebook-01.png"
import instagram from "../../components/assets/Instra-01.png"
import linkedin from "../../components/assets/Linkedin-01.png"
import { Link } from "react-router-dom";
import "../Contact/contact.css"
// import "../../App.css"
import "./styles.css"
import { useMediaQuery } from 'react-responsive'
import "../Footer/footer.css"

const Header = () => {

    const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 767 })
        return isMobile ? children : null
    }

    const [isScrolled, setIsScrolled] = useState(false);
    const [open, setOpen] = useState(false);

    window.addEventListener("scroll", function () {
        let nav = document.querySelector("nav")
        let windowposition = window.scrollY > 120;
        nav.classList.toggle("navbar-scroll", windowposition)

    })
    const listenScrollEvent = () => setIsScrolled(window.scrollY > 120);
    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);
    }, []);

    return (
        <>

            <section className="page-title parallax-section about"  >
                <nav className="navbar navbar-expand-lg fixed-top stroke "  >
                    <NavLink to="/"><img src={isScrolled ? whiteLogo : coloredLogo}
                        className="logo"
                        alt={isScrolled ? "SmartLogger white logo" : "SmartLogger colored logo"}
                    /></NavLink>
                    <input type="checkbox" id="click" />
                    <label for="click" onClick={() => setOpen(!open)} class="menu-btn navbar-toggler bg-warning menu-btn">
                        <ion-icon name={open ? 'close' : 'menu'}></ion-icon>
                    </label>

                    <ul className="navbar-nav ms-auto  py-5 mb-lg-0 fs-5 my-2 mx-5" style={{ cursor: "pointer" }}>
                        <li className="nav-item ">
                            <Link className="nav-link " aria-current="page" to="/">Home </Link>
                        </li>
                        <li className="nav-item">
                            <Link smooth className="nav-link  " to="/about">About </Link>
                        </li>
                        <li className="nav-item">
                            <Link smooth className="nav-link  " to="/services">Services </Link>
                        </li>
                        <li className="nav-item">
                            <Link smooth className="nav-link  " to="/client">Clients</Link>
                        </li>
                        <li className="nav-item">
                            <Link smooth className="nav-link " to="/portfolio">Portfolio</Link>
                        </li>


                        <li className="nav-items">
                            <Link smooth className="nav-link nav-button  btn btn-outline-warning  d-inline-block" to="/contact">Contact</Link>
                        </li>

                        <Mobile>
                            <div class="social-icons">
                                <div className="col-lg-12 d-flex justify-content-center my-2 ">
                                    <a href="https://www.facebook.com/azcreativesol" target="_blank"><img src={facebook} className="socialimages" /></a>
                                    <a href="https://www.linkedin.com/company/azcreativesol" target="_blank"><img src={linkedin} className="socialimages" /></a>
                                    <a href="https://www.instagram.com/azcreativesol" target="_blank"><img src={instagram} className="socialimages" /></a>

                                </div>
                            </div>
                        </Mobile>
                    </ul>

                </nav>

            </section>

        </>
    )
}

export default Header