import Header from '../Header/Header'
import clientimg from "../../Images/ClientsDesktop.jpg"
import clientimg1 from "../../Images/ClientsMobile.jpg"
import "./client.css"
import Footer from '../Footer/Footer'
import ClientPage from './ClientPage'
import "../../App.css"
import "../Services/service.css"
import { useMediaQuery } from 'react-responsive'
const Client = () => {
    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 800 })
        return isDesktop ? children : null
    }
   
    const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 767 })
        return isMobile ? children : null
    }
    return (
        <>
         
                <Header />
                <section >
                    <div className="slide-wraps dark-overlay">
                        <div className="slidecontent">
                            <div className="container-fluid" >
                                <div>
                                  <Desktop>  <img src={clientimg} className="clientimage " alt="backround" /></Desktop>
                                    <Mobile><img src={clientimg1} className="clientimage " alt="backround" /></Mobile>
                                </div>
                                <h1 className='clienth1 text-center text-light '>Our Clients</h1>

                            </div>
                        </div>
                    </div>
                    <div class="skewed-masks">
                        <div class="mask-blocks" >
                        </div>
                    </div>
                </section>
            <section>
                <div id='client'>
                    <ClientPage />
                </div>
            </section>
            <section >
                <Footer />
            </section>

        </>
    )
}

export default Client