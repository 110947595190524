import "../Contact/contact.css"
import pixel from "../../Images/ContactDesktop.jpg"
import pixel1 from "../../Images/ContactMobile.jpg"
import Footer from "../Footer/Footer"
import "../../App.css"
import Form from '../Form/Form';
import AOS from 'aos';
import 'aos/dist/aos.css'
import { HashLink as Link } from "react-router-hash-link";
import Header from "../Header/Header";
import { useMediaQuery } from 'react-responsive'

AOS.init();
const Contact = () => {
    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 800 })
        return isDesktop ? children : null
    }

    const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 767 })
        return isMobile ? children : null
    }
    return (
        <>
            <div id='wrapper'  >
                <Header />
                <section>
                    <div className="slide-wrap dark-overlay">
                        <div className="slidecontent">
                            <div className="container-fluid" >
                                <div>
                                    <Desktop>  <img src={pixel} className="serviceimage " alt="backround" /></Desktop>
                                    <Mobile><img src={pixel1} className="serviceimage " alt="backround" /></Mobile>
                                </div>
                                <p className='contactp text-center text-light ' >HOW MAY WE HELP YOU?</p>
                                <h1 className='contacth1 text-center text-light  ' >Contact Us</h1>

                            </div>
                        </div>
                    </div>
                    <div class="skewed-masks">
                        <div class="mask-blocks" >
                        </div>
                    </div>
                </section>
            </div>
            <div className='fom'>
                <Form />
            </div>

            <section className=''>
                <Footer />
            </section>
            <div className="whatsapp">
                <Link to="https://wa.me/+14692889263" target="_blank" className='fs-1 rounded-circle  btn btn-success text-light animate__heartBeat' data-aos="fade-down"  ><i className="fab fa-whatsapp"></i></Link>
            </div>
        </>
    )
}
export default Contact