import './App.css';
import Router from './routes/Router';
import React, { useState, useEffect } from 'react';

const App = () => {
  
  return (
    <div>
     
        <Router />
     
    </div>
  );
}

export default App;
