import "../Client/client.css"
import "../Contact/contact.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
import clientimg from "../../Images/Clients.png"

AOS.init();
const ClientPage = () => {
  return (
    <div className='' data-aos="fade-right"
      data-aos-duration="1000" id='client'>
      <h1 className='clientsh1 text-center '>Clients</h1>
      <p className='clientpara text-center'>
        We collaborate with companies all over the world, including over 100+ start-up companies. Our company has worked with clients in almost every sector and industry creating powerful digital marketing programs that emphasize customer  acquisition and an enhanced online digital presence. Our prized list of clients reads like  a Who’s Who of top companies from Big Technology, Entertainment, Financial Services, Retail, Education, and Manufacturing.
      </p>
      <img src={clientimg} className="clientimg text-center" alt='clientimg' />
      <br /><br />
    </div >
  )
}

export default ClientPage