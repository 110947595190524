import '../../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for style
import aboutimage from "../../Images/1.png"

AOS.init();
const About = () => {

    return (
        <>
            <section className="about text-dark " id="about" >
                <div className="container " >
                    <div className="section1 "  >
                        <p className='span  '>We sow and reap creative ideas</p>
                        <p className='para text-center '>AZ Creative is a  full-service digital agency that focuses on providing holistic creative, IT and digital solutions since 2021. We build your business from scratch, and turn it into an ideal brand for your target customers. We have first-hand subject matter expertise on the dynamic nature of IT services and products around the world, and we rigorously work to stay ahead of the knowledge to provide the best service to our clients. We want to be the first place you go for all your website design, graphic design, development, search engine optimization (SEO), social media, app development, digital marketing, logo, branding and all other digital needs. We promise to provide the best and most efficient service today for the future of your business tomorrow!
                        </p>
                    </div>
                    <div className=''>
                        <p className='aboutpa2 text-center'><span className='span1 text-warning text-center d-block mt-5'>How we work <br /></span> <span className='span2'> We are experts in bringing</span> <br /> <span className='span3'> Brands to life digitally </span></p>
                        <img className='abtimage ' src={aboutimage} data-aos="fade-right" data-aos-duration="1000" />
                    </div>

                </div>
            </section>
        </>
    )
}

export default About