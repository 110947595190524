import React from 'react'
import Header from '../Header/Header'
import serviceimg from "../../Images/ServiceDesktop.jpg"
import serviceimg1 from "../../Images/ServiceMobile.jpg"
import Footer from '../Footer/Footer'
import Service from './service'
import "./service.css"
import "../../App.css"
import { useMediaQuery } from 'react-responsive'
const Seriveces = () => {
    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 1200 })
        return isDesktop ? children : null
    }
   
    const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 767 })
        return isMobile ? children : null
    }
    return (
        <>

            <div id='wrapper'  >
                <Header />
                <section>
                    <div className="slide-wrap dark-overlay">
                        <div className="slidecontent">
                            <div className="container-fluid" >
                                <div>
                                   <Desktop><img src={serviceimg} className="serviceimage " alt="backround" /></Desktop> 
                                   <Mobile><img src={serviceimg1} className="serviceimage " alt="backround" /></Mobile>
                                </div>
                                <p className='servicep text-center text-light' >THIS IS WHAT WE DO.</p>
                                <h1 className='serviceh1 text-center text-light'>Our Services</h1>
 
                            </div>
                        </div>
                    </div>
                    <div className="skewed-masks">
                        <div className="mask-blocks" >
                        </div>
                    </div>
                </section>
            </div>

            <section className='servicesec ' >
                <Service />
            </section>

            <section>
                <Footer />
            </section>

        </>
    )
}

export default Seriveces