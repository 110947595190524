import About from '../About/AboutPage'
import Service from '../Services/service';
import Typewriter from 'typewriter-effect';
import "../../App.css"
import Client from '../Client/ClientPage';
import 'animate.css';
import Footer from "../Footer/Footer"
import "../Contact/contact.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import Header from '../Header/Header';
import background1 from "../../Images/1(3).jpg"
import background2 from "../../components/assets/1(4).jpg"
import { useMediaQuery } from 'react-responsive'

AOS.init();

const Navbar = () => {
    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 820 })
        return isDesktop ? children : null
    }

    const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 800 })
        return isMobile ? children : null
    }
    return (
        <>
            <Header />
            <div id='wrapper'  >
                <section>
                    <div className="slide-wrap dark-overlay">
                        <div className="slidecontent">
                            <div className="container-fluid" >
                                <div>
                                    <Desktop><img src={background1} className="image12 " alt="backround12" /></Desktop>
                                    <Mobile> <img src={background2} className="image123 " alt="backround123" /></Mobile>
                                </div>
                                <div className='main-div' data-aos="fade-right"
                                    data-aos-duration="3000">
                                    <h1 className="fontmedium 	" >Bring your imagination to reality<br /> </h1>
                                    <h1 className='build'> We Build:</h1>

                                    <div className='typewriter py-2 '>
                                        <Typewriter
                                            options={{
                                                autoStart: true,
                                                loop: true,
                                                delay: 40,
                                                strings: [

                                                    "GRAPHIC DESIGNING",
                                                    "WEB DEVELOPMENT",
                                                    "APP DEVELOPMENT",
                                                    "DIGITAL MARKETING",
                                                    "SEO",
                                                ]
                                            }}
                                        ></Typewriter>
                                    </div>
                                    <h4 className='product ' >We help companies build  amazing digital products</h4>
                                    <div className="btn-container inline-btn-container" >
                                        <Link to="/contact" className="service btn btn-light-out my-5 mx-2 px-4" tabindex="0">CONTACT </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="skewed-mask">
                        <div class="mask-block" >
                        </div>
                    </div>
                </section>
            </div>

            <section>
                <span className='main text-center  d-block text-center' >About</span>
                <About  />
            </section>


            <div className="col-lg-12  ">
                <h1 className="head text-center " >Our Services</h1>
                <p className="head-caption text-center"> What we provide </p>
                <Service />
            </div>

            <section>
                <div className="col-lg-12  py-4" >
                    <Client />
                </div>
            </section>


            <section>
                <Footer />
            </section>
            <div className="whatsapp">
                <a href="https://wa.me/+14692889263" target="_blank" className='fs-1 rounded-circle  btn btn-success text-light animate__heartBeat' data-aos="fade-down"  ><i className="fab fa-whatsapp"></i></a>
            </div>
        </>
    )
}

export default Navbar